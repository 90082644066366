<template>
    <div class="stream-rich-text-block">
        <RichTextElements
            :elements="data.richText"
            :pageContext="pageContext"
            :footnoteOffset="data.footnoteOffset"
            :theme="themeColor"
            :size="size"
        />
    </div>
</template>

<script setup>
import { inject } from 'vue';

defineProps({
    data: {
        type: Object,
        default: () => {},
    },
    size: {
        ...sizes,
    },
    ...streamBlock,
});
const themeColor = inject('tierTheme', 'light');
</script>
